export const PROGRAMS_STATUS = {
  NOT_STARTED: 1,
  IN_PROGRESS: 2,
  PUBLISHED: 3,
  NEEDS_REVIEW: 4,
  LEARFIELD_ONLY: 5,
  ARCHIVED: 6,
};

export const PROGRAMS_STATUS_NAMES = {
  [PROGRAMS_STATUS.NOT_STARTED]: 'Not Started',
  [PROGRAMS_STATUS.IN_PROGRESS]: 'In Progress',
  [PROGRAMS_STATUS.PUBLISHED]: 'Published',
  [PROGRAMS_STATUS.NEEDS_REVIEW]: 'Needs Review',
  [PROGRAMS_STATUS.LEARFIELD_ONLY]: 'Learfield Only',
  [PROGRAMS_STATUS.ARCHIVED]: 'Archived',
};

export const PROGRAMS_STATUS_COLORS = {
  [PROGRAMS_STATUS.NOT_STARTED]: 'error',
  [PROGRAMS_STATUS.IN_PROGRESS]: 'warning',
  [PROGRAMS_STATUS.PUBLISHED]: 'success',
  [PROGRAMS_STATUS.NEEDS_REVIEW]: 'info',
  [PROGRAMS_STATUS.LEARFIELD_ONLY]: 'primary',
  [PROGRAMS_STATUS.ARCHIVED]: 'grey',
};

export const PROGRAMS_STATUS_OPTIONS = [
  {
    value: PROGRAMS_STATUS.NOT_STARTED,
    text: PROGRAMS_STATUS_NAMES[PROGRAMS_STATUS.NOT_STARTED],
    color: PROGRAMS_STATUS_COLORS[PROGRAMS_STATUS.NOT_STARTED],
  },
  {
    value: PROGRAMS_STATUS.IN_PROGRESS,
    text: PROGRAMS_STATUS_NAMES[PROGRAMS_STATUS.IN_PROGRESS],
    color: PROGRAMS_STATUS_COLORS[PROGRAMS_STATUS.IN_PROGRESS],
  },
  {
    value: PROGRAMS_STATUS.PUBLISHED,
    text: PROGRAMS_STATUS_NAMES[PROGRAMS_STATUS.PUBLISHED],
    color: PROGRAMS_STATUS_COLORS[PROGRAMS_STATUS.PUBLISHED],
  },
  {
    value: PROGRAMS_STATUS.NEEDS_REVIEW,
    text: PROGRAMS_STATUS_NAMES[PROGRAMS_STATUS.NEEDS_REVIEW],
    color: PROGRAMS_STATUS_COLORS[PROGRAMS_STATUS.NEEDS_REVIEW],
  },
  {
    value: PROGRAMS_STATUS.LEARFIELD_ONLY,
    text: PROGRAMS_STATUS_NAMES[PROGRAMS_STATUS.LEARFIELD_ONLY],
    color: PROGRAMS_STATUS_COLORS[PROGRAMS_STATUS.LEARFIELD_ONLY],
  },
  {
    value: PROGRAMS_STATUS.ARCHIVED,
    text: PROGRAMS_STATUS_NAMES[PROGRAMS_STATUS.ARCHIVED],
    color: PROGRAMS_STATUS_COLORS[PROGRAMS_STATUS.ARCHIVED],
  },
];

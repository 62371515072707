const siteConfig = {
  appUrl: 'http://localhost:8080',
  apiUrl: 'https://digital-programs-api.staging.sidearmdev.com/api',
  // apiUrl: 'https://digital-programs-api.prod.sidearmdev.com/api',
  // sidearmApiUrl: 'https://api.staging.sidearmdev.com/',
  sidearmApiUrl: 'https://api.sidearmdev.com/',
  apiKey: 'I6AaB1vFQq7Ix1PLthGLD5L6Bs0fw3Eg2n6g75LV',
  frontendUrl: 'https://digital-programs-frontend.staging.sidearmdev.com',
  cognito: {
    redirectURI: '/login/oauth2/code/cognito',
    redirectURISignout: '/logout',
    userPoolId: 'test-cognito',
    appDomain: 'victor-test.auth.us-west-2.amazoncognito.com',
    clientId: '48uq6gpe7s51kjri3pshs58gja',
  },
  aws: {
    secretAccessKey: 'QhQkkuHpnWFd7SqPGbf0345xkPuWnaQfZN3BNI2t',
    accessKey: 'AKIAVDTRJ6R7QTXZZFZ2',
    region: 'us-east-1',
    bucket: 'victor-sidearm',
    digitalPrograms: {
      accessKey: 'AKIAVDTRJ6R7QTXZZFZ2',
      secretAccessKey: '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',
      region: 'us-east-1',
      bucket: 'victor-sidearm',
    },
    legacy: {
      accessKey: 'AKIAIQFHEJGPEROV5LRA',
      secretAccessKey: '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',
      region: 'us-east-1',
      bucket: '',
    },
    nextgen: {
      accessKey: 'AKIAUV7NRTIHA6UL2JQP',
      secretAccessKey: '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',
      region: 'us-east-2',
      bucket: 'sidearm.nextgen.sites',
    },
  },
};

export default siteConfig;

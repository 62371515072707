<template>
  <v-dialog class="page-preview-dialog" :value="visible" @input="setPagePreviewVisibility">
    <v-card height="90vh" class="d-flex flex-column" outlined rounded="lg">
      <v-card-title class="px-6 grey lighten-5">
        <span class="text-h6 text-capitalize">Page Preview</span>
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="setPagePreviewVisibility(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <div class="grey lighten-5 flex-grow-1 relative d-flex flex-column align-center justify-center overflow-hidden">
        <v-avatar style="z-index: 10" class="absolute primary--text page-number" tile color="grey lighten-4">
          {{ currentPage }}
        </v-avatar>
        <div
          v-if="previewType === 'mobile'"
          ref="wrapper"
          class="px-6 py-4 flex-grow-1 relative d-flex justify-center overflow-hidden"
        >
          <svg
            style="height: 100%; width: auto"
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="667.000000pt"
            height="1280.000000pt"
            viewBox="0 0 667.000000 1280.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
              <path
                d="M1000 12583 c-323 -25 -532 -121 -649 -299 -56 -86 -102 -214 -126
-352 -28 -164 -35 -428 -35 -1424 0 -944 -1 -978 -18 -978 -11 0 -27 -9 -38
-21 -17 -19 -19 -38 -19 -209 0 -171 2 -190 19 -209 11 -12 27 -21 38 -21 17
0 18 -13 18 -210 0 -197 -1 -210 -18 -210 -11 0 -27 -9 -38 -21 -17 -19 -19
-38 -19 -209 0 -171 2 -190 19 -209 11 -12 27 -21 37 -21 18 0 19 -124 22
-3557 3 -3269 4 -3567 20 -3667 68 -446 227 -650 567 -732 71 -17 202 -18
2500 -22 1814 -2 2448 0 2515 8 271 36 461 165 564 384 39 81 59 142 84 256
l21 95 3 3798 3 3797 29 0 c62 0 61 -10 61 492 0 428 -1 456 -18 471 -10 10
-30 17 -45 17 l-26 0 -4 1163 c-3 1044 -5 1171 -20 1247 -42 206 -98 323 -206
430 -108 108 -211 157 -406 197 -85 17 -216 18 -2440 19 -1292 0 -2370 -1
-2395 -3z"
              />
            </g>
          </svg>
          <div class="mobile-preview-wrapper" :style="{ transform: `scale(${previewZoom})` }">
            <iframe v-if="displayFrame" class="mobile-preview" :src="previewUrl" frameborder="0"></iframe>
          </div>
        </div>
        <div
          v-if="previewType === 'tablet'"
          ref="wrapper"
          class="px-6 flex-grow-1 relative d-flex justify-center overflow-hidden"
        >
          <svg
            style="height: 100%; width: auto"
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="1280.000000pt"
            height="1280.000000pt"
            viewBox="0 0 1280.000000 1280.000000"
            preserveAspectRatio="xMidYMid meet"
          >
            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
              <path
                d="M2552 12039 c-179 -23 -333 -168 -371 -347 -15 -71 -16 -10423 -1
-10503 31 -164 195 -344 375 -411 l60 -23 3765 -3 c2495 -2 3793 1 3849 8 56
6 106 20 148 39 117 55 214 180 242 309 15 72 15 10436 0 10502 -16 72 -65
160 -132 239 -71 85 -145 140 -232 173 l-60 23 -3790 1 c-2084 1 -3819 -2
-3853 -7z"
              />
            </g>
          </svg>
          <div class="tablet-preview-wrapper" :style="{ transform: `scale(${previewZoom})` }">
            <iframe v-if="displayFrame" class="tablet-preview" :src="previewUrl" frameborder="0"></iframe>
          </div>
        </div>
        <div v-if="previewType === 'desktop'" class="flex-grow-1 align-self-stretch">
          <iframe class="desktop-preview" v-if="displayFrame" :src="previewUrl" frameborder="0"></iframe>
        </div>
      </div>
      <v-divider></v-divider>
      <v-card-actions class="pa-3 grey lighten-5">
        <v-btn-toggle :value="previewType" @change="setPagePreviewType($event)" tile group color="primary">
          <v-btn value="mobile" icon depressed light class="rounded">
            <v-icon>$smartphone</v-icon>
          </v-btn>
          <v-btn value="tablet" icon depressed light class="rounded">
            <v-icon>$tablet</v-icon>
          </v-btn>
          <v-btn value="desktop" icon depressed light class="rounded">
            <v-icon>$desktop</v-icon>
          </v-btn>
        </v-btn-toggle>
        <v-spacer></v-spacer>
        <v-btn depressed class="text-capitalize" color="primary" @click="reloadPreview">Reload</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import config from '@/constants/config';
  import ClientService from '@/services/client/client';

  export default Vue.extend({
    name: 'PagePreviewModal',
    props: {
      currentPage: {
        type: Number,
        default: 1,
      },
    },
    data() {
      return {
        previewUrl: '',
        clientName: '',
        sportName: '',
        previewHeight: null as number,
        observer: null as ResizeObserver,
        displayFrame: true,
      };
    },
    computed: {
      ...mapGetters('programs', { program: 'getProgram' }),
      ...mapGetters('user', { token: 'getAuthToken' }),
      ...mapGetters('builder', {
        visible: 'displayPagePreviewModal',
        previewType: 'getPreviewType',
        programId: 'getProgramId',
      }),
      previewZoom(): number {
        const { previewHeight } = this;
        if (this.previewType === 'mobile') return (previewHeight - previewHeight * 0.2) / (375 / 0.5625);
        if (this.previewType === 'tablet') return (previewHeight - previewHeight * 0.18) / (768 / 0.75);
        return 1;
      },
      // previewUrl() {
      //   return `${config.frontendUrl}/${this.clientName}/${this.sportName}/${this.programId}/${this.currentPage}?auth-token=${this.token}`;
      // },
    },
    methods: {
      fetchSport: ClientService.fetchSport,
      fetchClient: ClientService.fetchClient,
      ...mapActions('builder', ['setPagePreviewVisibility', 'setPagePreviewType']),
      ...mapActions('programs', { fetchProgram: 'fetchProgram' }),
      setResizeListener() {
        const { previewType } = this;
        this.destroyResizeListener();
        if (previewType === 'mobile' || previewType === 'tablet')
          this.$nextTick(() => {
            const wrapper = this.$refs.wrapper as HTMLElement;
            const { height } = wrapper.getBoundingClientRect();
            this.previewHeight = height;
            const observer = new ResizeObserver((entries) => {
              const { height } = entries[0].contentRect;
              this.previewHeight = height;
            });
            observer.observe(this.$refs.wrapper as HTMLElement);
            this.observer = observer;
          });
      },
      destroyResizeListener() {
        if (this.observer) this.observer.disconnect();
      },
      reloadPreview() {
        // The following didn't work cause violates cross-origin policies
        // const frame = this.$refs.frame as HTMLIFrameElement;
        // frame.contentWindow.location.reload();
        this.displayFrame = false;
        this.$nextTick(() => {
          this.displayFrame = true;
        });
      },
    },
    mounted() {
      if (this.visible) {
        this.setResizeListener();
      }
    },
    watch: {
      async visible(visible: boolean) {
        if (visible) {
          await this.fetchProgram(this.programId);
          this.setResizeListener();
          const { clientId } = this.program;
          const { clientSportId } = this.program.sport;
          const client = await this.fetchClient(clientId);
          const sport = clientSportId ? await this.fetchSport({ clientId, clientSportId }) : null;
          this.clientName = client.tenantId;
          this.sportName = sport ? sport.shortName : 'programs';
          this.previewUrl = `${config.frontendUrl}/${this.clientName}/${this.sportName}/${this.programId}/${this.currentPage}?auth-token=${this.token}`;
        } else this.destroyResizeListener();
      },
      previewType() {
        this.setResizeListener();
      },
    },
  });
</script>
<style lang="scss" scoped>
  .page-number {
    width: 40px;
    height: 40px;
  }

  .mobile-preview-wrapper {
    position: absolute;
    transform-origin: 50% 0;
    top: 12.5%;
  }

  .mobile-preview {
    aspect-ratio: 9 / 16;
    width: 375px;
  }

  .tablet-preview-wrapper {
    position: absolute;
    transform-origin: 50% 0;
    top: 9%;
  }

  .tablet-preview {
    aspect-ratio: 3 / 4;
    width: 768px;
  }

  .desktop-preview {
    width: 100%;
    height: 100%;
  }

  .page-number {
    top: 16px;
    left: 16px;
  }
</style>

<template>
  <div>
    <v-row>
      <v-col class="pa-0">
        <v-btn icon :to="{ name: 'builder', query: { program: program.id } }">
          <v-icon>mdi-playlist-edit</v-icon>
        </v-btn>
      </v-col>
      <v-col class="pa-0">
        <v-menu class="elevation-2">
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              color="grey lighten-1"
              role="button"
              :aria-expanded="attrs['aria-expanded']"
              v-on="on"
              :disabled="loading"
              aria-controls="action-menu"
              aria-label="Action menu"
              type="button"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list id="action-menu" :disabled="loading" class="py-0">
            <v-list-item :to="{ name: 'builder', query: { program: program.id } }">
              <v-list-item-icon>
                <v-icon size="18">mdi-playlist-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Edit or resume working</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="onDuplicate">
              <v-list-item-icon>
                <v-icon size="18">mdi-content-copy</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Duplicate for a new project</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="onPublish">
              <v-list-item-icon>
                <v-icon size="18">mdi-upload-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Publish</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="onArchive">
              <v-list-item-icon>
                <v-icon size="18">mdi-archive-arrow-down-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Archive</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="onCopyURL">
              <v-list-item-icon>
                <v-icon size="18">mdi-link</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Copy & share program link</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="onPreviewPage">
              <v-list-item-icon>
                <v-icon size="18">$smartphone</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Preview</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
  import { PROGRAMS_STATUS } from '@/constants/programs';
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import config from '@/constants/config';
  import ClientService from '@/services/client/client';

  export default Vue.extend({
    props: {
      program: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        loading: false,
      };
    },
    methods: {
      fetchSport: ClientService.fetchSport,
      fetchClient: ClientService.fetchClient,
      ...mapActions('programs', ['fetchPrograms', 'changeStatus', 'duplicate']),
      ...mapActions('builder', ['setPagePreviewVisibility', 'setPagePreviewType', 'setPageProgram']),
      ...mapActions('ui', { showToast: 'showToast' }),
      async onChange(status: number) {
        try {
          this.loading = true;
          const { id, status: oldStatus } = this.program;
          await this.changeStatus({ id, status, oldStatus });
        } catch (error) {
          this.handleError(error, 'Could not change program status');
        } finally {
          this.loading = false;
        }
      },
      onPublish() {
        this.onChange(PROGRAMS_STATUS.PUBLISHED);
      },
      onArchive() {
        this.onChange(PROGRAMS_STATUS.ARCHIVED);
      },
      onPreviewPage() {
        this.setPageProgram(this.program.id);
        this.setPagePreviewType('mobile');
        this.setPagePreviewVisibility(true);
      },
      async onCopyURL() {
        try {
          const { clientId } = this.program;
          const client = await this.fetchClient(clientId);
          const { clientSportId } = this.program.sport;
          const sport = await this.fetchSport({ clientId, clientSportId });
          const sportShortName = sport ? sport.shortName : 'programs';
          const url = `${config.frontendUrl}/${client.tenantId}/${sportShortName}/${this.program.id}`;
          await navigator.clipboard.writeText(url);
          this.showToast('Copied to clipboard');
        } catch (err) {
          // console.error('Failed to copy: ', err);
        }
      },
      async onDuplicate() {
        try {
          this.loading = true;
          const { id } = this.program;
          await this.duplicate({ id });
          this.showToast('Program was duplicated successfully');
        } catch (error) {
          this.handleError(error, 'Could not duplicate program');
        } finally {
          this.loading = false;
        }
      },
      handleError(error: any, msg?: string) {
        msg = error.response?.data || msg || 'Was not possible to comunicate with server';
        this.showToast(msg);
        // console.warn(error);
      },
    },
  });
</script>

<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-dialog :value="value" @input="$emit('input', $event)" fullscreen persistent scrollable>
    <v-card>
      <v-card-title class="px-4 pt-4 gap-2 elevation-5" style="z-index: 10">
        Staff
        <v-spacer></v-spacer>
        <v-btn
          depressed
          class="text-capitalize"
          @click="onRemove"
          :disabled="
            items[tab] === 'Coaches'
              ? !selectedCoaches?.length
              : selectedSupportStaffs
              ? !selectedSupportStaffs?.length
              : false
          "
        >
          Remove
        </v-btn>
        <v-btn color="primary" depressed class="text-capitalize" @click="onAdd">Add Member</v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-tabs v-model="tab" grow class="px-4">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab v-for="item in items" :key="item">
            {{ item }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="px-4">
          <v-card flat>
            <div class="pt-0 my-4 d-flex flex-column gap-4">
              <!-- Coaches TABLE -->
              <v-data-table
                v-if="items[tab] === 'Coaches'"
                class="roster-table"
                disable-sort
                disable-filtering
                disable-pagination
                hide-default-footer
                :headers="headers"
                :items="coaches"
                item-key="id"
                show-select
                v-model="selectedCoachesItems"
                @toggle-select-all="selectAllItems($event, 'coaches')"
              >
                <template v-slot:body="{ items }">
                  <draggable v-model="coaches" item-key="name" tag="tbody" ghost-class="ghost">
                    <template>
                      <tr v-for="(item, index) in items" :key="item.id">
                        <td>
                          <v-simple-checkbox v-model="item.isSelected"></v-simple-checkbox>
                        </td>
                        <td>
                          <v-edit-dialog :return-value.sync="item.rosterHeadshot" large persistent @cancel="cancel">
                            <v-img
                              height="48"
                              width="48"
                              :alt="item?.rosterHeadshotAltText || 'Coach Headshot'"
                              :src="item?.rosterHeadshot || '/default-img.png'"
                              class="rounded-sm"
                            />
                            <template v-slot:input>
                              <div class="py-4">
                                <upload-image
                                  :value="item?.rosterHeadshot"
                                  height="200"
                                  width="200"
                                  :fileUrl="item.rosterHeadshot"
                                  @input="items[index].rosterHeadshot = $event"
                                  key="2"
                                  class="mb-5 mt-2"
                                />
                              </div>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td>
                          <v-edit-dialog persistent :return-value.sync="item.firstName">
                            <span v-if="item.firstName" :id="`${item.id}-0`">{{ item.firstName }}</span>
                            <span v-else class="text--disabled" :id="`${item.id}-0`">&mdash;</span>
                            <template v-slot:input>
                              <div class="py-4">
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  v-model="item.firstName"
                                  single-line
                                  @blur="triggerEnterKeyPressEvent($event.target.id)"
                                  @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.id, 0)"
                                  @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, item.id, 0)"
                                ></v-text-field>
                              </div>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td>
                          <v-edit-dialog persistent :return-value.sync="item.lastName">
                            <span v-if="item.lastName" :id="`${item.id}-1`">{{ item.lastName }}</span>
                            <span v-else class="text--disabled" :id="`${item.id}-1`">&mdash;</span>
                            <template v-slot:input>
                              <div class="py-4">
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  v-model="item.lastName"
                                  single-line
                                  @blur="triggerEnterKeyPressEvent($event.target.id)"
                                  @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.id, 1)"
                                  @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, item.id, 1)"
                                ></v-text-field>
                              </div>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td>
                          <v-edit-dialog :return-value.sync="item.headCoach" large>
                            <span v-if="item.headCoach">{{ item.headCoach }}</span>
                            <span v-else class="text--disabled">&mdash;</span>
                            <template v-slot:input>
                              <div class="py-4">
                                <v-radio-group outlined dense hide-details v-model="item.headCoach" single-line>
                                  <v-radio label="Yes" value="Y"></v-radio>
                                  <v-radio label="No" value="N"></v-radio>
                                </v-radio-group>
                              </div>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td>
                          <v-edit-dialog persistent :return-value.sync="item.title">
                            <span v-if="item.title" :id="`${item.id}-2`">{{ item.title }}</span>
                            <span v-else class="text--disabled" :id="`${item.id}-2`">&mdash;</span>
                            <template v-slot:input>
                              <div class="py-4">
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  v-model="item.title"
                                  single-line
                                  @blur="triggerEnterKeyPressEvent($event.target.id)"
                                  @keydown.tab.exact.prevent="handleTabPress($event.target.id, item.id, 2)"
                                  @keydown.shift.tab.prevent="handleShiftTabPress($event.target.id, item.id, 2)"
                                ></v-text-field>
                              </div>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td>
                          <v-edit-dialog large :return-value.sync="item.bio">
                            <!-- <span v-if="item.bio">Edit Bio </span> -->
                            <!-- <v-btn v-if="item.bio" icon> -->
                            <v-btn icon>
                              <v-icon>mdi-pencil</v-icon>
                              <!-- <span class="ml-1"> Edit Bio</span> -->
                            </v-btn>
                            <!-- <span v-else class="text--disabled">&mdash;</span> -->
                            <template v-slot:input>
                              <v-sheet class="pt-4" width="540">
                                <ckeditor v-model="item.bio" />
                              </v-sheet>
                            </template>
                          </v-edit-dialog>
                        </td>
                      </tr>
                    </template>
                  </draggable>
                </template>
                <template v-slot:item.data-table-select="{ isSelected, select }">
                  <v-simple-checkbox color="green" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                </template>
              </v-data-table>

              <!-- Support Team TABLE -->
              <v-data-table
                v-if="items[tab] === 'Support Staff'"
                class="roster-table"
                disable-sort
                disable-filtering
                disable-pagination
                hide-default-footer
                :headers="headersSupportTeam"
                :items="supportMembers"
                item-key="id"
                show-select
                v-model="selectedSupportMembersItems"
                @toggle-select-all="selectAllItems($event, 'support-staff')"
              >
                <template v-slot:body="{ items }">
                  <draggable v-model="supportMembers" item-key="name" tag="tbody" ghost-class="ghost">
                    <template>
                      <tr v-for="(item, index) in items" :key="item.id">
                        <td>
                          <v-simple-checkbox v-model="items[index].isSelected"></v-simple-checkbox>
                        </td>
                        <td>
                          <v-edit-dialog
                            :return-value.sync="item.rosterHeadshot"
                            large
                            persistent
                            @save="save"
                            @cancel="cancel"
                          >
                            <v-img
                              height="48"
                              width="48"
                              :alt="item?.rosterHeadshotAltText || 'Support Team Headshot'"
                              :src="item?.rosterHeadshot || '/default-img.png'"
                              class="rounded-sm"
                            />
                            <template v-slot:input>
                              <div class="py-4">
                                <upload-image
                                  :value="item?.rosterHeadshot"
                                  height="200"
                                  width="200"
                                  :fileUrl="item.rosterHeadshot"
                                  @input="items[index].rosterHeadshot = $event"
                                  key="2"
                                  class="mb-5 mt-2"
                                />
                              </div>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td>
                          <v-edit-dialog :return-value.sync="item.firstName">
                            <span v-if="item.firstName">{{ item.firstName }}</span>
                            <span v-else class="text--disabled">&mdash;</span>
                            <template v-slot:input>
                              <div class="py-4">
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  v-model="item.firstName"
                                  single-line
                                ></v-text-field>
                              </div>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <td>
                          <v-edit-dialog :return-value.sync="item.lastName">
                            <span v-if="item.lastName">{{ item.lastName }}</span>
                            <span v-else class="text--disabled">&mdash;</span>
                            <template v-slot:input>
                              <div class="py-4">
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  v-model="item.lastName"
                                  single-line
                                ></v-text-field>
                              </div>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <!-- <td>
                          <v-edit-dialog :return-value.sync="item.headCoach" large>
                            <span v-if="item.headCoach">{{ item.headCoach }}</span>
                            <span v-else class="text--disabled">&mdash;</span>
                            <template v-slot:input>
                              <div class="py-4">
                                <v-radio-group outlined dense hide-details v-model="item.headCoach" single-line>
                                  <v-radio label="Yes" value="Y"></v-radio>
                                  <v-radio label="No" value="N"></v-radio>
                                </v-radio-group>
                              </div>
                            </template>
                          </v-edit-dialog>
                        </td> -->
                        <td>
                          <v-edit-dialog :return-value.sync="item.title">
                            <span v-if="item.title">{{ item.title }}</span>
                            <span v-else class="text--disabled">&mdash;</span>
                            <template v-slot:input>
                              <div class="py-4">
                                <v-text-field
                                  outlined
                                  dense
                                  hide-details
                                  v-model="item.title"
                                  single-line
                                ></v-text-field>
                              </div>
                            </template>
                          </v-edit-dialog>
                        </td>
                        <!-- <td>
                          <v-edit-dialog large :return-value.sync="item.bio">
                            <span v-if="item.bio">Edit Bio </span>
                            <span v-else class="text--disabled">&mdash;</span>
                            <template v-slot:input>
                              <v-sheet class="pt-4" width="540">
                                <ckeditor v-model="item.bio" />
                              </v-sheet>
                            </template>
                          </v-edit-dialog>
                        </td> -->
                      </tr>
                    </template>
                  </draggable>
                </template>
                <template v-slot:item.data-table-select="{ isSelected, select }">
                  <v-simple-checkbox color="green" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions class="pa-4 elevation-5" style="z-index: 10">
        <v-spacer></v-spacer>
        <v-btn class="text-capitalize" depressed color="primary" @click="onSave">Done</v-btn>
        <v-btn class="text-capitalize" text @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import Vue, { PropType } from 'vue';
  import { cloneDeep, uniqueId } from 'lodash-es';
  import { CoachDetails, StaffRaw } from '@/store/client/types';
  import { UploadImage } from '@/components';
  import draggable from 'vuedraggable';
  import { mixins } from '../../mixin/mixins';
  export default Vue.extend({
    name: 'StaffEditModal',
    mixins: [mixins],
    components: { UploadImage, draggable },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      data: {
        type: Object as PropType<StaffRaw>,
        required: true,
      },
    },
    data() {
      return {
        tab: 0 as number,
        items: ['Coaches', 'Support Staff'] as string[],
        headers: [
          { text: 'Image', value: 'rosterHeadshot' },
          { text: 'First Name', value: 'firstName' },
          { text: 'Last Name', value: 'lastName' },
          { text: 'Head Coach', value: 'headCoach' },
          { text: 'Title', value: 'title' },
          { text: 'Bio', value: 'bio', cellClass: 'custom-cell' },
        ],
        headersSupportTeam: [
          { text: 'Image', value: 'rosterHeadshot' },
          { text: 'First Name', value: 'firstName' },
          { text: 'Last Name', value: 'lastName' },
          { text: 'Title', value: 'title' },
        ],
        coaches: [] as CoachDetails[],
        supportMembers: [] as CoachDetails[],
        selectedCoachesItems: [] as CoachDetails[],
        selectedSupportMembersItems: [] as CoachDetails[],
      };
    },
    methods: {
      handleTabPress(idElement: string, id: number, order: number) {
        this.triggerEnterKeyPressEvent(idElement);
        /* eslint-disable */
        this.moveNextElementModal(this.coaches, id, order);
      },
      handleShiftTabPress(idElement: any, id: number, order: number) {
        this.triggerEnterKeyPressEvent(idElement);
        /* eslint-disable */
        this.movePreviousElementModal(this.coaches, id, order);
      },
      save() {
        // console.log('Dialog Saved');
      },
      cancel() {
        // console.log('Dialog cancel');
      },
      open() {
        // console.log('Dialog open');
      },
      genId(): number {
        if (this.items[this.tab] === 'Coaches') {
          let id = parseInt(uniqueId());
          while (this.coaches.some((item) => item.id === id)) {
            id = parseInt(uniqueId());
          }
          return id;
        } else {
          let id = parseInt(uniqueId());
          while (this.supportMembers.some((item) => item.id === id)) {
            id = parseInt(uniqueId());
          }
          return id;
        }
      },
      onAdd() {
        if (this.items[this.tab] === 'Coaches') {
          this.coaches.push({
            id: this.genId(),
            title: null,
            firstName: null,
            lastName: null,
            headCoach: null,
            rosterHeadshot: null,
            rosterHeadshotAltText: null,
            masterImage: null,
            masterImageAltText: null,
            bio: null,
          });
        } else {
          console.log('🚀 ~ file: StaffEditModal.vue:402 ~ onAdd ~ this.supportMembers:', this.supportMembers);
          this.supportMembers.push({
            id: this.genId(),
            title: null,
            firstName: null,
            lastName: null,
            headCoach: null,
            rosterHeadshot: null,
            rosterHeadshotAltText: null,
            masterImage: null,
            masterImageAltText: null,
            bio: null,
          });
        }
      },
      onRemove() {
        if (this.items[this.tab] === 'Coaches') {
          const idsToRemove = this.selectedCoaches.map((item: CoachDetails) => item.id);
          this.coaches = this.coaches.filter((item) => !idsToRemove.includes(item.id));
        } else {
          const idsToRemove = this.selectedSupportStaffs.map((item: CoachDetails) => item.id);
          this.supportMembers = this.supportMembers.filter((item) => !idsToRemove.includes(item.id));
        }
      },
      onSave() {
        this.coaches.map((c) => {
          delete c?.isSelected;
          return c;
        });
        if (this.supportMembers) {
          this.supportMembers.map((c) => {
            delete c?.isSelected;
            return c;
          });
        }
        this.$emit('submit', { coaches: this.coaches, supportMembers: this.supportMembers });
        this.close();
      },
      close() {
        this.$emit('input', false);
      },
      async selectAllItems({ value }: { items: CoachDetails[]; value: boolean }, key: string) {
        await this.$nextTick();
        if (key === 'coaches') {
          this.coaches = this.coaches.map((item) => {
            return {
              ...item,
              isSelected: value,
            };
          });
        } else {
          this.supportMembers = this.supportMembers.map((item) => {
            return {
              ...item,
              isSelected: value,
            };
          });
        }
      },
    },
    computed: {
      selectedCoaches(): CoachDetails[] {
        return this.coaches.filter((item: CoachDetails) => item.isSelected);
      },
      selectedSupportStaffs(): CoachDetails[] {
        return this.supportMembers ? this.supportMembers.filter((item: CoachDetails) => item.isSelected) : [];
      },
    },
    watch: {
      value(visible: boolean) {
        if (visible) {
          this.coaches = cloneDeep(this.data.members);
          this.supportMembers = this.data?.supportMembers ? cloneDeep(this.data?.supportMembers) : [];
          this.selectedCoachesItems = [];
          this.selectedSupportMembersItems = [];
        }
      },
    },
  });
</script>
<style lang="scss">
  .cell-truncate {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    min-height: 1.5rem;
  }

  .roster-table {
    .custom-cell {
      max-width: 100px;

      .v-small-dialog__activator {
        @extend .cell-truncate;

        .v-small-dialog__activator__content {
          @extend .cell-truncate;
          display: block;
        }
      }
    }
  }
</style>

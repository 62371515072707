<template>
  <v-dialog persistent width="720" :value="value" @input="$emit('input', $event)">
    <v-card>
      <v-card-title class="pt-24 pb-4">{{ title }}</v-card-title>
      <v-card-text class="px-6 py-0">
        <label for="text-area">{{ label }}</label>
      </v-card-text>
      <div class="px-6 pb-6">
        <ckeditor v-model="html"></ckeditor>
      </div>
      <v-card-actions class="px-6 pb-6 pt-0">
        <v-btn class="text-capitalize" color="primary" depressed @click="onSave">Done</v-btn>
        <v-btn class="text-capitalize" text @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
  import Vue from 'vue';
  export default Vue.extend({
    name: 'HtmlModal',
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      data: {
        type: Object,
      },
    },
    data() {
      return {
        html: '',
      };
    },
    methods: {
      async onSave() {
        this.$emit('submit', { html: this.html });
        this.close();
      },
      close() {
        this.$emit('input', false);
      },
    },
    watch: {
      value(visible: boolean) {
        if (visible) this.html = this.data.html;
      },
    },
  });
</script>
<style lang="scss" scoped>
  .custom-editor {
    overflow-y: auto;
    cursor: pointer;

    :deep(.ce-settings) {
      left: -66px;
    }
  }
</style>

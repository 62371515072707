<template>
  <v-app>
    <a href="#main-content" class="skip-link">Skip to main content</a>
    <component :is="layout" />
    <v-snackbar app :value="toastVisible" @input="hideToast">
      {{ toastContent }}
      <template #action="{ attrs }">
        <v-btn icon dark v-bind="attrs" @click="hideToast">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import { Layout } from './components';
  import { AuthLayout } from './components/AuthLayout';

  export default Vue.extend({
    components: {
      default: Layout,
      auth: AuthLayout,
    },
    computed: {
      layout() {
        return this.$route.meta.layout || 'default';
      },
      ...mapGetters('ui', ['toastVisible', 'toastContent']),
    },
    methods: {
      ...mapActions('ui', ['hideToast']),
    },
  });
</script>
<style lang="scss">
  .skip-link {
    background-color: #fff;
    color: #00f !important;
    display: inline-block;
    left: -99999px;
    margin: 5px;
    padding: 10px;
    position: absolute;
    top: 0;
    &:focus {
      outline-style: solid;
      outline-color: #0ff;
      outline-width: 5px;
      outline-offset: 0;
      transition: none;
      left: 0;
      z-index: 1000;
    }
  }
</style>

<template>
  <div>
    <v-card-text class="scrollable">
      <label class="text--secondary">Select coaches and/or Support staff to import</label>

      <v-tabs v-model="tab" grow class="px-4">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="px-4">
        <v-tab-item v-for="item in items" :key="item">
          <v-card flat>
            <div v-if="item === 'Coaches'" class="pt-0 my-4 d-flex flex-column gap-4">
              <v-data-table
                class="elevation-2 mt-2 custom-table"
                :class="{ invalid: !isFormValid }"
                checkbox-color="primary"
                :headers="headers"
                disable-pagination
                hide-default-footer
                show-select
                fixed-header
                sort-by="rank"
                item-key="id"
                :items="coaches"
                v-model="selected"
                height="360"
                :loading="isLoading"
              ></v-data-table>
            </div>
            <div v-if="item === 'Support Staff' && supportTeam" class="pt-0 my-4 d-flex flex-column gap-4">
              <v-data-table
                class="elevation-2 mt-2 custom-table"
                :class="{ invalid: !isFormValid }"
                checkbox-color="primary"
                :headers="headers"
                disable-pagination
                hide-default-footer
                show-select
                fixed-header
                sort-by="rank"
                item-key="id"
                :items="supportTeam"
                v-model="selectedSupportTeam"
                height="360"
                :loading="isLoading"
              ></v-data-table>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-actions class="pa-4 elevation-5">
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="goBack" :disabled="isFormDisabled">
        Back
      </v-btn>
      <v-btn
        type="submit"
        color="primary"
        depressed
        class="text-capitalize"
        :disabled="!isFormValid || isFormDisabled"
        @click="onSubmit"
      >
        Continue
      </v-btn>
      <span v-if="!isFormValid" class="error--text">Select at least one coach</span>
      <v-spacer></v-spacer>
      <v-btn depressed color="grey lighten-4" class="text-capitalize" @click="reset" :disabled="isFormDisabled">
        Clear
      </v-btn>
      <v-btn text class="text-capitalize elevation-0" @click="close">Cancel</v-btn>
    </v-card-actions>
  </div>
</template>
<script lang="ts">
  import Vue, { VueConstructor } from 'vue';
  import ClientService from '@/services/client/client';
  import { mapActions, mapGetters } from 'vuex';
  import { Coach } from '@/store/client/types';
  import { sortBy } from 'lodash-es';
  interface Injections {
    setPageTitle: (title: string) => void;
    setPageContent: (content: any) => void;
    toggleLoading: () => void;
    getPageSport: () => any;
    goNext: () => void;
    goBack: () => void;
    close: () => void;
  }
  export default (Vue as VueConstructor<Vue & Injections>).extend({
    name: 'CoachImporter',
    inject: ['setPageTitle', 'setPageContent', 'toggleLoading', 'getPageSport', 'goNext', 'goBack', 'close'],
    data() {
      return {
        tab: 0 as number,
        // items: ['Coaches', 'Support Staff'] as string[],
        coaches: [] as Coach[],
        supportTeam: [] as Coach[],
        selected: [] as Coach[],
        selectedSupportTeam: [] as Coach[],
        headers: [
          {
            text: 'Rank',
            align: 'start',
            value: 'rank',
          },
          {
            text: 'First Name',
            align: 'start',
            value: 'firstName',
          },
          {
            text: 'Last Name',
            align: 'start',
            value: 'lastName',
          },
          {
            text: 'Title',
            align: 'start',
            value: 'title',
          },
        ],
        isLoading: false,
        isFormValid: true,
        isFormDisabled: false,
      };
    },
    computed: {
      ...mapGetters('programs', { program: 'getProgram' }),
      items(): string[] {
        // if (this.supportTeam) {
        return ['Coaches', 'Support Staff'];
      },
      // return ['Coaches'];
      // },
    },
    methods: {
      ...mapActions('ui', { showToast: 'showToast' }),
      fetchCoaches: ClientService.fetchCoaches,
      fetchSupportTeam: ClientService.fetchSupportTeam,
      fetchCoachesMembers: ClientService.fetchCoachesMembers,
      fetchClient: ClientService.fetchClient,

      async importCoaches() {
        const { clientId } = this.program;

        const client = await this.fetchClient(clientId);
        const teamInfo = this.program.event.home;
        const membersIds = this.selected.map(({ id }) => id);
        const coachesMap = await this.fetchCoachesMembers({ clientId, membersIds });
        const members = Object.values(coachesMap);
        members.map((p) => {
          const httpRegex = /(http|https)/;
          if (p.rosterHeadshot !== '' && !httpRegex.test(p.rosterHeadshot)) {
            p.rosterHeadshot = `${client.siteUrl}${p.rosterHeadshot}`;
          }
          if (!p.rosterHeadshot.includes('images')) {
            p.rosterHeadshot = teamInfo.logoUrl;
          }
        });
        const headerText = 'Coaches';
        this.setPageTitle(`${headerText} Import`);
        return {
          blocks: [
            {
              id: 1,
              data: { text: headerText },
              type: 'header',
              config: {
                label: 'Describe what this page is about',
                title: 'Page Heading',
                placeholder: 'Page Heading Text Goes Here...',
              },
            },
            {
              id: 2,
              type: 'textimage',
              config: {
                label: 'Choose which team this page is associated with',
                title: 'Team Heading',
                imageLabel: 'School Logo',
                displayConfig: true,
                firstTextDefault: 'mascot',
                secondTextDefault: 'name',
                placeholderFirstText: 'School Nickname/Mascot',
                placeholderSecondText: 'School Name',
              },
              data: {
                alt: `${teamInfo.mascot} team logo`,
                image: teamInfo.logoUrl,
                firstText: teamInfo.mascot,
                secondText: teamInfo.name,
                locationType: 'home',
              },
            },
            {
              id: 3,
              data: {
                members,
                supportMembers: this.selectedSupportTeam,
              },
              type: 'staff',
              config: {
                title: 'Coaches Panel',
                subtitle: 'Components that build your coaches page. Coach info member cards',
              },
            },
          ],
        };
      },
      async onSubmit() {
        this.isFormValid = !!this.selected.length;
        if (!this.isFormValid) return;
        try {
          this.toggleLoading();
          this.isFormDisabled = true;
          const content = await this.importCoaches();
          this.setPageContent(content);
          this.goNext();
        } catch (error) {
          const msg = error?.response?.data || 'Could not import coaches data';
          this.showToast(msg);
          // // console.warn(error);
        } finally {
          this.toggleLoading();
          this.isFormDisabled = false;
        }
      },
      reset() {
        this.selected = [];
        this.isFormValid = true;
      },
    },
    async created() {
      try {
        this.isFormDisabled = true;
        this.isLoading = true;

        const { clientId } = this.program;
        const { tenantId } = await this.fetchClient(clientId);

        let clientSportId = this.program.sport.clientSportId;
        if (!clientSportId) {
          let pageSport = this.getPageSport();
          clientSportId = pageSport.clientSportId;
        }

        const coaches = await this.fetchCoaches({ clientId, clientSportId });
        this.coaches = coaches.map((p, i) => {
          return {
            ...p,
            rank: i + 1,
          };
        });

        const supportTeam = await this.fetchSupportTeam({ tenantId, sportId: clientSportId });
        this.supportTeam = supportTeam.map((p, i) => {
          return {
            ...p,
            rosterHeadshot: p?.image?.absoluteUrl,
            rank: i + 1,
          };
        });
      } catch (error) {
        this.showToast('Could not fetch coaches/Support team data');
        // // console.warn(error);
      } finally {
        this.isFormDisabled = false;
        this.isLoading = false;
      }
    },
    watch: {
      selected(selected: Coach[]) {
        if (selected && selected.length) this.isFormValid = true;
      },
    },
  });
</script>

import { render, staticRenderFns } from "./NewProgramModal.vue?vue&type=template&id=085ef8ef&scoped=true"
import script from "./NewProgramModal.vue?vue&type=script&lang=ts"
export * from "./NewProgramModal.vue?vue&type=script&lang=ts"
import style0 from "./NewProgramModal.vue?vue&type=style&index=0&id=085ef8ef&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "085ef8ef",
  null
  
)

export default component.exports
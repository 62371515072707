<template>
  <div>
    <v-card outlined>
      <v-card-title class="px-4">
        {{ config.title }}
        <v-spacer></v-spacer>
        <!-- <v-btn class="text-capitalize" depressed color="grey lighten-4 mr-4" @click="toggleModal">Import</v-btn> -->
        <v-btn class="text-capitalize" depressed color="grey lighten-4" @click="modal = !modal">Bulk Edit</v-btn>
      </v-card-title>
      <v-card-subtitle class="px-4 text--secondary">{{ config.subtitle }}</v-card-subtitle>
      <v-tabs v-model="tab" grow class="px-4">
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="px-4">
        <v-tab-item v-for="item in items" :key="item">
          <v-card flat>
            <div class="pt-0 my-4 d-flex flex-column gap-4">
              <v-card
                v-for="(schedule, index) in value[items[tab] === 'Results' ? 'results' : 'upcoming']"
                outlined
                class="py-4"
                :key="index"
              >
                <v-list-item>
                  <v-list-item-avatar class="my-0 rounded" size="48" tile>
                    <v-img
                      height="48"
                      width="48"
                      :src="regex.test(schedule?.opponentLogo) ? schedule?.opponentLogo : '/default-img.png'"
                      class="rounded-sm"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content class="py-0">
                    <v-list-item-title class="mb-2 text-body-2 font-weight-bold">
                      {{ schedule?.locationIndicator === 'H' ? 'vs' : 'at' }}
                      {{ schedule?.opponent || schedule?.location }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="d-flex align-center gap-2 text-caption">
                      <!-- <span class="font-weight-bold text--primary">
                        {{ schedule?.date }}
                      </span> -->
                      <span v-format-date:[dateFormat]="schedule?.date"></span>
                      <v-divider vertical />
                      {{ schedule?.time }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row class="d-flex align-center">
                      <span
                        v-if="items[tab] === 'Results' && !(typeof schedule?.result === 'object')"
                        class="font-weight-bold text--primary mr-4"
                      >
                        {{ schedule?.result }}
                      </span>
                      <span
                        v-if="items[tab] === 'Results' && typeof schedule?.result === 'object'"
                        class="font-weight-bold text--primary mr-4"
                      >
                        {{ schedule?.result?.status }} {{ schedule?.result?.teamScore }}-{{
                          schedule?.result?.opponentScore
                        }}
                      </span>
                      <!-- <v-btn
                        class="text-capitalize mr-4"
                        depressed
                        color="grey lighten-4"
                        @click="toggleEditModal(schedule, items[tab])"
                      >
                        Edit
                      </v-btn> -->
                      <v-btn
                        icon
                        tile
                        class="text-capitalize grey lighten-4 rounded text--primary mr-2"
                        @click="
                          items[tab] === 'Results' ? onRemove(schedule?.resultId) : onRemove(schedule?.upcomingId)
                        "
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <!-- <schedule-edit-modal
                  v-model="editModal"
                  :data="modalInfo"
                  @submit="$emit('input', { players: $event })"
                /> -->
              </v-card>
              <template v-if="value[items[tab] === 'Results' ? 'results' : 'upcoming']">
                <tr class="v-data-table__empty-wrapper">
                  <td colspan="100">No data available</td>
                </tr>
              </template>
            </div>
            <!-- <div class="d-flex justify-end">
              <v-btn depressed class="text-capitalize mb-4" color="primary">Add New Event</v-btn>
            </div> -->
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <schedule-bulk-edit-modal
      v-model="modal"
      :data="value"
      @submit="$emit('input', { results: $event.results, upcoming: $event.upcoming })"
    />
    <!-- <schedule-edit-modal v-model="editModal" :type="infoType" :data="modalInfo" :program="program" /> -->
  </div>
</template>
<script lang="ts">
  import { ScheduleEvents } from '@/store/client/types';
  import Vue, { PropType } from 'vue';
  import { mapGetters } from 'vuex';
  // import ScheduleEditModal from './ScheduleEditModal.vue';
  import ScheduleBulkEditModal from './ScheduleBulkEditModal.vue';

  // declare module 'vue/types/vue' {
  //   interface Vue {
  //     items: any;
  //     tab: any;
  //     onRemove: () => void;
  //   }
  // }

  export default Vue.extend({
    name: 'ScheduleBlock',
    components: {
      // ScheduleEditModal,
      ScheduleBulkEditModal,
    },
    props: {
      config: {
        type: Object,
        required: true,
      },
      value: {
        type: Object as PropType<{ results: ScheduleEvents[]; upcoming: ScheduleEvents[] }>,
        required: true,
      },
    },
    data() {
      return {
        dateFormat: 'M/d/yy',
        modal: false as boolean,
        editModal: false as boolean,
        infoType: '' as string,
        // modalInfo: null,
        tab: 0 as unknown,
        items: ['Upcoming Events', 'Results'] as unknown,
        regex: /(http|https)/,
      };
    },
    computed: {
      ...mapGetters('programs', { program: 'getProgram' }),
      results(): ScheduleEvents[] {
        return this.value.results;
      },
      upcoming(): ScheduleEvents[] {
        return this.value.upcoming;
      },
    },
    methods: {
      // toggleEditModal(modalInfo: Results | UpcomingEvents, infoType: string) {
      //   this.infoType = infoType;
      //   this.modalInfo = modalInfo;
      //   this.editModal = !this.editModal;
      // },
      // toggleModal(): void {
      //   this.modal = !this.modal;
      // },
      onRemove(id: number | null): void {
        if (!id) return;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.items[this.tab] === 'Results') {
          const { results } = this.value;
          const i = results.findIndex((r: ScheduleEvents) => r.resultId === id);
          if (i >= 0) results.splice(i, 1);
        } else {
          const { upcoming } = this.value;
          const i = upcoming.findIndex((upcom: ScheduleEvents) => {
            return upcom.upcomingId === id;
          });
          if (i >= 0) upcoming.splice(i, 1);
        }
      },
    },
  });
</script>

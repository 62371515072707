<style lang="scss">
  .select-colored.v-select {
    &.v-select--is-menu-active > .v-input__slot,
    &.v-input--is-focused > .v-input__slot {
      border-radius: inherit !important;
    }

    .v-input__slot {
      min-height: 40px;
      height: 40px;
    }

    .v-select__slot {
      justify-content: center;
    }

    &.v-text-field--outlined fieldset {
      border-width: 0px;
    }

    .v-select__selections {
      flex: 0 0 auto;
      width: initial;

      input {
        margin: 0px;
        max-width: 0px;
        min-width: 0px;
        width: 0px;
      }
    }

    .v-select__selection--comma {
      max-width: inherit;
      margin-right: 0px;
    }

    .v-input__append-inner {
      flex-shrink: initial;
      margin-top: 8px;
      margin-left: 0px;
      padding: 0;
    }
  }

  .select-colored-content .v-list {
    padding-top: 0px;
    padding-bottom: 0px;
  }
</style>
<template>
  <v-select
    :background-color="color"
    class="select-colored font-weight-bold text-caption"
    dark
    hide-details
    :menu-props="{ maxHeight: 400, contentClass: 'select-colored-content', offsetY: true }"
    :items="items"
    rounded
    outlined
    :value="value"
    @input="onInput"
    :disabled="loading"
  >
    <template #prepend-item>
      <v-subheader class="text-uppercase">Status</v-subheader>
    </template>
    <template #item="{ item: { color, text }, on, attrs }">
      <v-list-item v-on="on" v-bind="attrs" class="align-stretch pl-0">
        <v-sheet class="mr-4" :color="color" width="8px"></v-sheet>
        <v-list-item-content>
          <v-list-item-title>{{ text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>
<script lang="ts">
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import { PROGRAMS_STATUS_COLORS, PROGRAMS_STATUS_OPTIONS } from '@/constants/programs';
  export default Vue.extend({
    props: {
      program: {
        type: [String, Number],
        required: true,
      },
      value: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        loading: false,
      };
    },
    computed: {
      items() {
        return PROGRAMS_STATUS_OPTIONS;
      },
      color(): string | undefined {
        return PROGRAMS_STATUS_COLORS[this.value];
      },
    },
    methods: {
      ...mapActions('programs', { changeStatus: 'changeStatus' }),
      ...mapActions('ui', { showToast: 'showToast' }),
      async onInput(status: number) {
        const oldStatus = this.value;
        try {
          this.loading = true;
          await this.changeStatus({
            id: this.program,
            oldStatus,
            status,
          });
        } catch (error) {
          const msg = error.response?.data || 'Could not change program status';
          this.showToast(msg);
        } finally {
          this.loading = false;
        }
      },
    },
  });
</script>
